<template>
	<div class="flex items-center justify-center vld-parent">

    <!-- Loading animation -->
    <loading
      :active="isLoading"
      :is-full-page="false"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0.7"
    />

		<div class="max-w-xl w-full p-10 rounded-lg shadow-md bg-white">
			<h2 class="mb-5 text-center text-3xl leading-9 font-extrabold text-primary-500">
        Восстановление пароля
      </h2>

      <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
        <p
          v-if="showMessage"
          class="p-2 w-full rounded-md text-white" 
          :class="[!showError ? 'bg-green-600 px-16 py-16' : 'bg-red-700']"
        >            
          <span v-if="!showError" class="block px-3 font-semibold pb-6">
            <font-awesome-icon icon="check" class="text-5xl p-2 mx-auto my-6 text-green-600 bg-white rounded-full" /><br>
            Письмо для восстановления отправлено!
          </span>

          <span v-if="showError">
            <font-awesome-icon icon="times" class="mr-1" />
            Аккаунт не найден!
          </span>
        </p>
      </fade-transition>

			<form class="mt-2 max-w-full" @submit.prevent="recoverClick()" v-if="!showMessage || showError">

        <!-- Email field -->
        <div class="relative">
          <input
            aria-label="Email address"
            v-model="email"
            @change="$v.email.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.email.$error,
              'focus:border-green-300 text-primary-500' : !$v.email.$error }"
            class="appearance-none relative block w-full px-3 py-2 border placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Адрес электронной почты"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.email.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <slide-x-left-transition :duration="300" mode="out-in">
                <font-awesome-icon v-if="!$v.email.$error" key="1" icon="check" class="text-green-700"/>
                <font-awesome-icon v-if="$v.email.$error" key="2" icon="times" class="text-red-700"/>
              </slide-x-left-transition>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.email.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.email.email">Неправильный формат e-mail!</span>
              <span v-if="!$v.email.required">Требуется заполнить e-mail!</span>
            </div>
          </collapse-transition>
        </div>

        <button 
          type="submit"
          class="group mr-4 mt-4 relative w-1/3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md
          text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
        >
          Отправить
        </button>

        <button
          @click="$xmodal.close()"
          class="group mt-4 relative w-1/3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md
          text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
        >
          Отмена
        </button>

			</form>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required, sameAs, minLength, requiredIf, helpers } from 'vuelidate/lib/validators'
import { SlideXLeftTransition, CollapseTransition } from 'vue2-transitions'

import { FadeTransition } from 'vue2-transitions'

import { mapState, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
	name: 'RecoverPassModal',
  mixins: [validationMixin],
  components: { SlideXLeftTransition, CollapseTransition, Loading, FadeTransition },
	data: function() {
		return {
      email: null,
      isLoading: false,
      showError: false,
      showMessage: false
    }
	},
  validations: {
    email: {
      email,
      required
    }
  },
  methods: {
    ...mapActions({ 
      sendToken: 'user/passwordRecoverySendToken',
    }),
    recoverClick() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.showMessage = false
      this.isLoading = true

      this.sendToken(this.email)
        .then(() => {
          this.showError = false
          setTimeout(() => { this.$xmodal.close() }, 1300)
        })
        .catch((error) => {
          this.showError = true
        })
        .finally(() => {
          this.showMessage = true
          this.isLoading = false
        })
    }
  }
}
</script>